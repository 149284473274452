
	import { Component, Vue, Watch } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IconTarget from '@pixcap/ui-library/components/Icons/IconTarget.vue';
	import IntersectVideoAutoplay from '@pixcap/ui-core/plugins/intersect-component/IntersectVideoAutoplay.vue';
	import IconCaretLeft from '@pixcap/ui-library/components/Icons/IconCaretLeft.vue';
	import IconCaretRight from '@pixcap/ui-library/components/Icons/IconCaretRight.vue';

	@Component({
		name: 'MetricsSection',
		components: { IconCaretRight, IconCaretLeft, IntersectVideoAutoplay, IconTarget, BodyText, HeadingText },
	})
	export default class MetricsSection extends Vue {
		activeIndex = 1;
		transitionEnabled = true;
		carouselItems = [
			{
				hasComparision: true,
				isVideoThumbnail: false,
				oldThumbnail: `${process.env.ROOT_URL}/cdn/explore/homepage/metrics/android-old.webp`,
				newThumbnail: `${process.env.ROOT_URL}/cdn/explore/homepage/metrics/android-new.gif`,
				text: this.$t('page.home.metrics.carousel.content.item_1.text'),
				name: 'Google',
				description: this.$t('page.home.metrics.carousel.content.item_1.description'),
			},
			{
				hasComparision: false,
				isVideoThumbnail: false,
				thumbnail: `${process.env.ROOT_URL}/cdn/explore/homepage/metrics/samsung.webp`,
				text: this.$t('page.home.metrics.carousel.content.item_2.text'),
				name: 'Samsung',
				description: this.$t('page.home.metrics.carousel.content.item_2.description'),
			},
			{
				hasComparision: false,
				isVideoThumbnail: false,
				thumbnail: `${process.env.ROOT_URL}/cdn/explore/homepage/metrics/meta.gif`,
				text: this.$t('page.home.metrics.carousel.content.item_3.text'),
				name: 'Meta',
				description: this.$t('page.home.metrics.carousel.content.item_3.description'),
			},
			{
				hasComparision: true,
				isVideoThumbnail: false,
				oldThumbnail: `${process.env.ROOT_URL}/cdn/explore/homepage/metrics/reddit-old.webp`,
				newThumbnail: `${process.env.ROOT_URL}/cdn/explore/homepage/metrics/reddit-new.mp4`,
				posterUrl: `${process.env.ROOT_URL}/cdn/explore/homepage/metrics/reddit-new-poster.webp`,
				text: this.$t('page.home.metrics.carousel.content.item_4.text'),
				name: 'Reddit',
				description: this.$t('page.home.metrics.carousel.content.item_4.description'),
			},
			{
				hasComparision: false,
				isVideoThumbnail: true,
				thumbnail: `${process.env.ROOT_URL}/cdn/explore/homepage/metrics/airbnb.mp4`,
				posterUrl: `${process.env.ROOT_URL}/cdn/explore/homepage/metrics/airbnb-poster.webp`,
				text: this.$t('page.home.metrics.carousel.content.item_5.text'),
				name: 'AirBnb',
				description: this.$t('page.home.metrics.carousel.content.item_5.description'),
			},
		];

		intervalTimeout = 2400;
		carouselInterval;

		get extendedItems(): any[] {
			return [this.carouselItems[this.carouselItems.length - 1], ...this.carouselItems, this.carouselItems[0]];
		}

		checkIfVideoType(url) {
			return url.endsWith('.mp4');
		}

		@Watch('activeIndex')
		onCurrentIndexChange() {
			this.resetPositionIfNeeded();
		}

		resetPositionIfNeeded() {
			this.$nextTick(() => {
				if (this.activeIndex === 0) {
					setTimeout(() => {
						this.transitionEnabled = false;
						this.activeIndex = this.carouselItems.length;
						this.$nextTick(() => {
							setTimeout(() => {
								this.transitionEnabled = true;
							}, 30);
						});
					}, 300);
				} else if (this.activeIndex === this.extendedItems.length - 1) {
					setTimeout(() => {
						this.transitionEnabled = false;
						this.activeIndex = 1;
						this.$nextTick(() => {
							setTimeout(() => {
								this.transitionEnabled = true;
							}, 30);
						});
					}, 300);
				}
			});
		}

		handleNextItem() {
			if (this.activeIndex < this.extendedItems.length - 1) {
				this.activeIndex++;
			}
		}

		handlePreviousItem() {
			if (this.activeIndex > 0) {
				this.activeIndex--;
			}
		}

		handleHoverCarousel() {
			clearInterval(this.carouselInterval);
		}

		handleUnhoverCarousel() {
			this.carouselInterval = setInterval(this.handleNextItem, this.intervalTimeout);
		}

		created() {
			this.$nextTick(() => {
				if (this.$refs.refCarouselContainer && this.$refs.refCarouselCard) {
					this.carouselInterval = setInterval(this.handleNextItem, this.intervalTimeout);
				}
			});
		}

		mounted() {
			this.resetPositionIfNeeded();
		}

		beforeDestroy() {
			clearInterval(this.carouselInterval);
		}
	}
