
	import { Component, Vue } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import IconBlender from '@pixcap/ui-library/components/Icons/brands/IconBlender.vue';
	import IconMaya from '@pixcap/ui-library/components/Icons/brands/IconMaya.vue';
	import IconCinema4D from '@pixcap/ui-library/components/Icons/brands/IconCinema4D.vue';
	import IconFigma from '@pixcap/ui-library/components/Icons/brands/IconFigma.vue';
	import IconSketch from '@pixcap/ui-library/components/Icons/brands/IconSketch.vue';
	import IconPowerPoint from '@pixcap/ui-library/components/Icons/brands/IconPowerPoint.vue';
	import IconAdobeIllustrator from '@pixcap/ui-library/components/Icons/brands/IconAdobeIllustrator.vue';
	import IconCanva from '@pixcap/ui-library/components/Icons/brands/IconCanva.vue';
	import IconAdobePhotoshop from '@pixcap/ui-library/components/Icons/brands/IconAdobePhotoshop.vue';
	import IconAdbobeAfterEffects from '@pixcap/ui-library/components/Icons/brands/IconAdbobeAfterEffects.vue';
	import IconGoogle from '@pixcap/ui-library/components/Icons/brands/IconGoogle.vue';
	import IconWebflow from '@pixcap/ui-library/components/Icons/brands/IconWebflow.vue';

	@Component({
		name: 'CompatibleSection',
		components: { BodyText, HeadingText },
	})
	export default class CompatibleSection extends Vue {
		compatibleList = [
			{
				name: 'Blender',
				icon: IconBlender,
			},
			{
				name: 'Maya',
				icon: IconMaya,
			},
			{
				name: 'Cinema 4D',
				icon: IconCinema4D,
			},
			{
				name: 'Figma plugin',
				icon: IconFigma,
				badge: this.$t('common_elements.new'),
			},

			{
				name: 'Sketch',
				icon: IconSketch,
			},
			{
				name: 'PowerPoint',
				icon: IconPowerPoint,
			},
			{
				name: 'Webflow plugin',
				icon: IconWebflow,
				badge: this.$t('common_elements.new'),
			},
			{
				name: 'Canva',
				icon: IconCanva,
			},
			{
				name: 'Photoshop',
				icon: IconAdobePhotoshop,
			},
			{
				name: 'Illustrator',
				icon: IconAdobeIllustrator,
			},
			{
				name: 'After Effects',
				icon: IconAdbobeAfterEffects,
			},
			{
				name: 'G Suite',
				icon: IconGoogle,
			},
		];
	}
